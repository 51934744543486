<template>
  <div class="workflows">
    <Notification />
    <h1>Workflows</h1>
    <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="loading-component"/>
    <div class="workflows-main">
      <SearchBar @searchList="search" @resetList="clear"/>
      <div class="project-select">
        <div v-if="projects !== null">
          <div class="project-select-items" v-for="project, index in projects" :key="index">
            <span>{{project.name}}</span>
            <input type="checkbox" @click="handleSelectProject(project)" :checked="project.active"/>
          </div>
        </div>
      </div>
      <div class="status-select">
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']"/>
        <div v-if="(statuses !== null || statuses !== undefined) && !isLoading">
          <div class="status-select-items" v-for="status, index in statuses" :key="index">
            <span>{{status.name}}</span>
            <input type="checkbox" @click="handleSelectStatus(status)" :checked="status.active"/>
          </div>
        </div>
      </div>
      <table id="workflow-table">
        <thead>
          <tr id="results-tab">
            <td v-if="isLoading"><small><font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']"/></small></td>
            <td v-if="!isLoading"><small>Displaying {{workflows.length === 1? "1 Workflow" : workflows.length + " Workflows"}}</small></td>
          </tr>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Project</th>
            <th>Status</th>
            <th>Updated</th>
          </tr>
        </thead>
        <tbody v-if="!isLoading">        
          <tr v-for="wf in workflows" :key="wf.workflow_id">
            <td><router-link :to="{ name: 'Workflow', params: {workflowId: wf.workflow_id, projectName: wf.project}}">{{ wf.name }}</router-link></td>         
            <td>{{wf.description}}</td>  
            <td>{{ wf.project}}</td>
            <td>{{ wf.status}}</td>
            <td>{{wf.last_update !== ""? showDate(wf.last_update) : showDate(wf.created)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import SelectList from '../components/Generics/SelectList.vue'
import displayDate from "../utils/dateHandler.js"
import Notification from '../components/Generics/Notification.vue'
import SearchBar from '../components/Generics/SearchBar.vue'


export default {
  name: 'Workflows',
  props: {
    projectName: String
  },
  components: {
    SelectList,
    Notification,
    SearchBar
  },
  computed: {
    ...mapGetters({
      workflows: "getFilteredWorkflows",
      isLoading: "isLoading",
      selectedProject: "getSelectedProject",
      selectedStatus: "getSelectedStatus",
      projects: "getProjects",
      statuses: "getStatuses",
      chosenProject: "getChosenProject",
    })
  },
  methods: {
    ...mapActions(['getAllWorkflows','filterWorkflowProject', 'filterWorkflowStatus', 'loadProjects', 'loadProjectMetadata', 'searchWorkflows','clearWorkflowSearch']),
    handleSelectProject(project){
      project.active = !project.active
      this.loadProjectMetadata(project)
      this.getAllWorkflows()
    },
    handleSelectStatus(status){
      status.active = !status.active
      this.filterWorkflowStatus(status)
    },
    handleFilterWorkflowProject(projectSelection){
      if(projectSelection === ""){
        this.getAllWorkflows()
      }
      else {
        this.filterWorkflowProject(projectSelection)
      }
    },
    handleFilterWorkflowStatus(statusSelection){
      if(statusSelection === ""){
        this.getAllWorkflows()
      }
      else {
        this.filterWorkflowStatus(statusSelection)
      }
    },
    showDate(date){
      return displayDate(date)
    },
    search(searchVal){
      this.searchWorkflows(searchVal)
      this.getAllWorkflows()
    },
    clear(){
      this.clearWorkflowSearch()
      this.getAllWorkflows()
    }
  },
  async mounted () {
    this.clearWorkflowSearch()
    await this.getAllWorkflows(this.projectName)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#results-tab{
  border:10px solid red;
}
.status-select,.project-select{
  margin:auto;
  text-align: center;
}
.status-select-items,.project-select-items{
  display: inline-flex;
  justify-content: center;
  margin: 1rem;  
}

.app-middle{
  text-align: center;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin:1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

</style>
